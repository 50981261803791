import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Home from "../components/home"
import Logo from "../images/fb-banner-01.jpg"

// siteTitle={data.site.siteMetadata.title}

const IndexPage = () => (
  <Layout >

    <SEO
      title='Home'
      image={Logo}
    />

    <Home/>

  </Layout>
)

export default IndexPage
