import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import SignupForm from "./signupform"
import Hero from "./hero"
import Posts from "./posts"
import HomePosts from "./homeposts"

const Home = () => (
  <home>

  <Hero/>

  <HomePosts/>

    <div class="section signup_upper">
      <div class="container">
        <div class="row">
          <div class="column">

          <a name="signup" class="anchor">
          <h3>Find out more</h3>
          </a>

          <p class="desc">
            If you&apos;re wanting to accelerate your journey to success,
            then take the first step and stay in touch with our newsletter.
          </p>

        </div>
      </div>
    </div>
  </div>

  <div class="section signup_lower">
    <div class="container">
      <div class="row">
        <div class="one-half column">

          <h5>Signup to our newsletter</h5>
          <SignupForm/>

        </div>
        <div class="one-half column">
          <h5>Reach out to us</h5>
          <p>
            We are looking for highly motivated people to join our movement who have an
            insatiable drive to inspire growth, change and development
            in women.
          </p>
          <p>
            Do you have a desire to impart a motivating and encouraging message onto an enthusiastic and
            impressionable audience?  Do you want to make a positive difference in the
            lives of women?  If so, then we
            invite you to contact us as we’re like-minded.
          </p>
          <p>
            <a href="https://www.linkedin.com/in/sara-trimboli">Message on LinkedIn</a>
          </p>

        </div>
      </div>
    </div>
  </div>



  </home>
)


Home.propTypes = {
  siteTitle: PropTypes.string,
}

Home.defaultProps = {
  siteTitle: ``,
}

export default Home
