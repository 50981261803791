import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Carousel from 'nuka-carousel'
import PostItem from './postitem'
import "./css/homeposts.css"

import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"
import moment from 'moment'


import PostCard from './postcard'

const HomePosts = () =>  {

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
    {
      allMarkdownRemark(
        sort: {
          fields: [frontmatter___date]
          order: DESC
        }
        limit:5
      ) {
        totalCount
        edges {
          node {
            frontmatter {
              date
              path
              title
              author
              description
              duration
              image
            }
          }
        }
      }
    }
    `
  )


  const posts = allMarkdownRemark.edges;
  const latest_post = posts.slice(0,1)[0];
  const second_post = posts.slice(1,2)[0];
  const recent_posts = posts.slice(2);

  const bannerStyleA = {
    color: 'white',
    backgroundImage: 'url(/' + latest_post.node.frontmatter.image + ')',
    padding: '0rem 0rem 0rem',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    minHeight: '30rem'
  }

  const bannerStyleB = {
    color: 'white',
    backgroundImage: 'url(/thumbs/' + second_post.node.frontmatter.image + ')',
    padding: '0rem 0rem 0rem',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    minHeight: '20rem'
  }

  return (

    <div>


    <div class="section posts">
      <div class="container">

          <div class="row">
            <div class="column">
              <h3>Latest Posts</h3>
              <p class="desc">
                Find inspiration, and handy how-to guides in our <Link to="/blog">latest posts</Link>.
              </p>
            </div>
          </div>


        <div class="row post_row">

            <PostCard
              size='two-thirds'
              banner={bannerStyleA}
              path={latest_post.node.frontmatter.path}
              title={latest_post.node.frontmatter.title}
              description={latest_post.node.frontmatter.description}
              duration={latest_post.node.frontmatter.duration}
              date={latest_post.node.frontmatter.date}
              />

            <PostCard
              size='one-third'
              banner={bannerStyleB}
              path={second_post.node.frontmatter.path}
              title={second_post.node.frontmatter.title}
              description={second_post.node.frontmatter.description}
              duration={second_post.node.frontmatter.duration}
              date={second_post.node.frontmatter.date}
              />

        </div>


      <div class="row post_row">
        {recent_posts.map(function(item,i){

         return(
              <PostCard
                size='one-third'
                banner={{
                  color: 'white',
                  backgroundImage: 'url(/thumbs/' + item.node.frontmatter.image + ')',
                  padding: '0rem 0rem 0rem',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  minHeight: '20rem'
                }}
                path={item.node.frontmatter.path}
                title={item.node.frontmatter.title}
                description={item.node.frontmatter.description}
                duration={item.node.frontmatter.duration}
                date={item.node.frontmatter.date}
                />
              )
          })
        }
        </div>

        <Link to="/blog">Read the latest posts</Link>

        </div>
      </div>

    </div>

  )
}

export default HomePosts
