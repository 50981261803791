import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Logo from "./logo"

const Hero = ({ siteTitle }) => (
  <hero>


      <div class="section hero">

        <div class="container">
          <div class="one-third column">

          <Link to="/">
            <Logo size="hfj_logo"/>
          </Link>

          <p class="desc">
            Informing and inspiring women on their journey to financial success
          </p>
          </div>
        </div>

      </div>

      <div class="section hero_stack">
      </div>


  </hero>
)

Hero.propTypes = {
  siteTitle: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
